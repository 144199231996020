<template>
	<pui-datatable
		:modelName="modelName"
		:parentModelName="parentModelName"
		:parentModel="parentModel"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:readOnly="readOnly"
		:showMasterDetailFilterListBtn="true"
		:showMasterDetailFilterBtn="true"
		:showMasterDetailSortBtn="true"
		:showMasterDetailConfBtn="true"
		:showDeleteBtn="true"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'mportfacilities-grid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'mportfacilities'
		};
	}
};
</script>
